import React from "react";

type FloatingLabelProps = {
    name?: string;
    value?: string;
    label?: string;
    type?: string;
    required?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
    error?: string;
    status?: string;
    disabled?: boolean;
    min?: string;
    max?: string;
    step?: number;
};

const FloatingLabelInput = ({
    name,
    value,
    label,
    type,
    required,
    onChange,
    onFocus,
    error,
    status,
    disabled,
    min,
    max,
    step,
}: FloatingLabelProps) => (
    <div className="relative z-0 w-full my-5 group font-avenirBook">
        <input
            className="block py-2.5 pl-2 w-full text-sm text-gray-900 bg-transparent border-0 border-b-[2px] border-[#101828]/20 appearance-none focus:outline-none focus:ring-0 focus:border-blue-300 peer"
            id={name}
            type={type}
            placeholder=""
            onChange={onChange}
            onFocus={onFocus}
            name={name}
            value={value}
            required={required}
            disabled={disabled}
            min={min}
            step={step}
            max={max}
        />
        <label
            htmlFor={name}
            className="absolute text-sm text-gray-500 pl-2 duration-300 transform -translate-y-6 scale-75 top-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-500  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
        >
            {label || "Input Label"}
            {required && <span className="text-red-600 ">*</span>}
        </label>
        {error && (
            <p className="mt-2 text-xs text-red-600 ">
                <span className="font-medium">{error}</span>
            </p>
        )}
        {status && (
            <p className="mt-2 text-sm text-slate-600 ">
                <span className="font-light">{status}</span>
            </p>
        )}
    </div>
);

FloatingLabelInput.defaultProps = {
    name: "", // name of the input
    value: "", // value of the input
    label: "Input Label", // label of the input
    type: "text", // type of the input
    required: false, // whether the input is required
    onChange: () => {}, // callback when the input changes
    onFocus: () => {}, // callback when the input gains focus
    error: "", // error message
    status: "", // status message
    disabled: false, // whether the input is disabled
    min: "",
    max: "",
    step: 10,
};

export default FloatingLabelInput;
