import axios from "../../../axios";

export const getAllStaffApi = async (
    pageLimit?: any,
    pageNumber?: any,
    query?: any
) => {
    try {
        let url = `/lolastaffs?pageNumber=${pageNumber}&pageLimit=${pageLimit}`;
        if (query) {
            Object.keys(query).forEach(key => {
                url += `&${key}=${query[key]}`;
            });
        }
        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const createStaffApi = async (payload: any) => {
    try {
        const url = "/lolastaffs";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const assignStaffApi = async (payload: any) => {
    try {
        const url = "/lolastaffs/assign";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const deAssignStaffApi = async (payload: any) => {
    try {
        const url = "/lolastaffs/deassign";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const deleteStaffApi = async (lolaStaffUserId: string) => {
    try {
        const url = `/lolastaffs/${lolaStaffUserId}`;
        const res = await axios.delete(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};
