import React, { useId } from "react";
import DrawerPanel from "../../../components/elements/DrawerPanel/DrawerPanel";
import formatWord from "../../../utils/formatWord";

type Props = {
    open: boolean;
    toggleModal: () => void;
    details: any;
    userRecord: any;
};

const Row = ({ title, value }: { title: string; value: any }) => (
    <div className="flex items-center justify-between py-4 text-xs border-b border-black border-dotted sm:text-sm">
        <div className="text-left">{title}:</div>
        <div className="text-right">{value}</div>
    </div>
);

const TerminalRequestDetail = ({
    open,
    toggleModal,
    details,
    userRecord,
}: Props) => {
    const id = useId();

    return (
        <DrawerPanel
            open={open}
            handleClose={toggleModal}
            title="Terminal Request Detail"
        >
            <div className="font-avenirBook px-4 text-center">
                <div className="py-4 mb-2 text-xs bg-white">
                    {Object.entries(details).map(d => (
                        <Row
                            title={formatWord(d[0])}
                            value={d[1]}
                            key={id + d[0]}
                        />
                    ))}
                </div>
            </div>
        </DrawerPanel>
    );
};

export default TerminalRequestDetail;
