/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import generatePDF, { Margin, Options, Resolution } from "react-to-pdf";
import { Modal } from "rsuite";
import StatementOfAccount from "../../../components/blocks/vend/StatementOfAccount";
import { ErrorToast } from "../../../components/blocks/Alert";
import { DrawerButton } from "../../../components/elements/Button";
import FloatingLabelInput from "../../../components/elements/Inputs/FloatingLabelInput";
import Loading from "../../../components/elements/Loading/Loading";
import { retrieveWalletStatementApi } from "../../../redux/slice/communities/communities.api";
import dateToISO from "../../../utils/dateToISO";
// import { retrieveWalletStatementApi } from "../../../actions";

type Props = {
    open: boolean;
    community: any;
    handleClose: () => void;
};
type TState = { from: string; to: string };

const AccountStatement = ({
    open,
    community: communityInformation,
    handleClose,
}: Props) => {
    const [date, setDate] = useState<TState>({
        from: "",
        to: "",
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [statement, setStatement] = useState<Record<string, any>>({
        isFetched: false,
        data: {
            transactions: [],
        },
    });

    // console.log("Community: ", communityInformation)

    const handleChange = (e: any) => {
        const { name } = e.target;
        const { value } = e.target;

        setDate({ ...date, [name]: dateToISO(value) });
    };

    const handleSubmit = async (option: TState) => {
        setLoading(true);

        await retrieveWalletStatementApi({
            ...option,
            commId: communityInformation?.id,
        })
            .then(response => {
                alert("Fetched");
                setLoading(false);
                setStatement({
                    isFetched: true,
                    data: response.data,
                });
            })
            .catch(error => {
                setLoading(false);
                ErrorToast(error);
                setStatement({
                    isFetched: false,
                    data: {
                        transactions: [],
                    },
                });
            });
    };

    const ref = React.createRef<HTMLDivElement>();
    const options: Options = {
        filename: `${date.from}_${date.to}/${communityInformation?.communityName}`,
        resolution: Resolution.LOW,
        method: "save",
        page: { format: "A4", margin: Margin.MEDIUM, orientation: "portrait" },
        overrides: {},
    };

    useEffect(() => {
        if (!statement.isFetched) return;
        console.log("Statement: ", statement);
    }, [statement]);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size={statement.data?.transactions?.length > 0 ? "lg" : "xs"}
            backdropClassName="flex justify-center items-center"
            overflow
        >
            <Modal.Header>
                <Modal.Title className="font-bold font-avenirBook">
                    Generate Account Statement
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {statement.data?.transactions?.length > 0 ? (
                    <section className="min-w-full">
                        <div
                            role="button"
                            onClick={() => generatePDF(ref, options)}
                            className="my-3 text-white text-center font-bold bg-blue-600 p-3 rounded w-fit ml-auto mr-2"
                        >
                            Click to Download Statement
                        </div>
                        <div className="">
                            <StatementOfAccount
                                forwardRef={ref}
                                data={statement?.data}
                                dates={date}
                                communityInformation={communityInformation}
                            />
                        </div>
                    </section>
                ) : (
                    <div className="">
                        <h4>Please choose a date range.</h4>
                        <div className="py-1">
                            <FloatingLabelInput
                                value={date.from}
                                onChange={handleChange}
                                name="from"
                                type="date"
                                label="Start date"
                            />
                        </div>
                        <div className="py-1">
                            <FloatingLabelInput
                                value={date.to}
                                onChange={handleChange}
                                name="to"
                                type="date"
                                label="End date"
                            />
                        </div>
                        <div>
                            <DrawerButton onClick={() => handleSubmit(date)}>
                                {loading ? (
                                    <Loading size="sm" />
                                ) : (
                                    "Generate Statement"
                                )}
                            </DrawerButton>
                        </div>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default AccountStatement;
