/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from "react";
import DataTable from "react-data-table-component";
import { HiDotsVertical } from "react-icons/hi";
import { Button, Dropdown } from "rsuite";
import formatDate from "../../../utils/formatDate";
// import TableSubHeader from "../../../components/blocks/TableSubHeader";
import PosIcon2 from "../../../assets/images/logo/pos-terminal 2.png";
import {
    ErrorToast,
    InfoToast,
    SuccessToast,
} from "../../../components/blocks/Alert";
import Loading from "../../../components/elements/Loading/Loading";
import { UpdateATerminalRequest } from "../../../redux/slice/posterminals/posterminals.api";
import capitalizeFistLetterInSentence from "../../../utils/capitalizeFirstLetter";
import TerminalRequestDetails from "./TerminalRequestDetails";
// import { CustomButton } from "../../../components/elements/Button";
// import FilterByStatus from "./filter/FilterByStatus";

const renderIconButton = (props: any, ref: any) => (
    <Button type="button" ref={ref} {...props}>
        <HiDotsVertical />
    </Button>
);

const terminalDropdownActions = [
    { id: 0, title: "Approve", slug: "accepted" },
    { id: 1, title: "Reject", slug: "rejected" },
];

type Props = {
    terminalRequested: any[];
    loading: boolean;
    refetch(): void;
};

const TerminalRequested = ({ terminalRequested, loading, refetch }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [terminalRequestDetails] = useState<any>({});
    const [selectedRow, setSelectedRow] = useState({});
    // const [status, setStatus] = useState([]);
    // const [queryObject, setQueryObject] = useState({});
    // const [showFilter, setShowFilter] = useState(false);
    // const [pagination, setPagination] = useState({
    //     perPage: 10,
    //     page: 1,
    // });

    const handleClick = async (status: string, requestId: string) => {
        setIsLoading(true);

        try {
            const res = await UpdateATerminalRequest({ requestId, status });
            console.log("terminal response", res);
            if (res.successful) {
                SuccessToast(res.message);
                refetch();
            } else {
                InfoToast(res.message);
            }
        } catch (error) {
            ErrorToast(error);
        }

        setIsLoading(false);
    };

    const showDetails = (row: any) => {
        setSelectedRow({
            reference: row.accountReference,
            communityName: row.detail.name,
            first_name: row.detail.communityOwnerUserRecord.first_name,
            last_name: row.detail.communityOwnerUserRecord.last_name,
            numberOfDevices: row.numberOfDevices,
            status: row.status,
            entityType: row.entityType,
            phone_number: row.detail.communityOwnerUserRecord.phone_number,
            created_at: formatDate(row.created_At),
            updated_at: formatDate(row.updated_At),
        });
        setShowModal(true);
    };

    const columns = [
        {
            name: "Created At",
            selector: (row: any) => formatDate(row.createdAt),
            grow: 1,
        },
        {
            name: "No. Of Device Requested",
            selector: (row: any) => row.numberOfDevices,
        },
        {
            name: "Status",
            selector: (row: any) => capitalizeFistLetterInSentence(row.status),
        },
        {
            name: "Updated At",
            selector: (row: any) => formatDate(row.updatedAt),
        },
        {
            cell: useCallback(
                (row: any) => (
                    <Dropdown
                        renderToggle={renderIconButton}
                        placement="bottomEnd"
                    >
                        <div className="mt-2">
                            {terminalDropdownActions.map(
                                ({ id, title, slug }) => (
                                    <Dropdown.Item
                                        onClick={() =>
                                            handleClick(slug, row._id)
                                        }
                                        key={id}
                                        className="w-[150px] px-3"
                                    >
                                        {title}
                                    </Dropdown.Item>
                                )
                            )}
                        </div>
                    </Dropdown>
                ),
                []
            ),
        },
    ];

    return (
        <div>
            <div className="w-full">
                <div>
                    {terminalRequested.length < 0 ? (
                        <div className="flex flex-col items-center justify-center mt-14">
                            <div className="items-center justify-center w-20 h-16 mb-8">
                                <img src={PosIcon2} alt="" />
                            </div>
                            <div>
                                <p className="w-64 h-20 text-sm font-normal text-center font-avenirBook">
                                    You have no terminal request. Request a new
                                    terminal to get started
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="terminal-table ">
                            <DataTable
                                data={terminalRequested}
                                columns={columns}
                                highlightOnHover
                                responsive
                                pointerOnHover
                                subHeader
                                subHeaderWrap
                                onRowClicked={showDetails}
                                progressPending={isLoading || loading}
                                progressComponent={<Loading />}
                                pagination
                            />
                        </div>
                    )}
                </div>
                {showModal && (
                    <TerminalRequestDetails
                        open
                        toggleModal={() => setShowModal(false)}
                        details={selectedRow}
                        userRecord={terminalRequestDetails}
                    />
                )}
            </div>
        </div>
    );
};

export default TerminalRequested;
