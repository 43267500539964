import React from "react";
import { Modal } from "rsuite";
import { CustomButton } from "../../elements/Button";
import Loading from "../../elements/Loading/Loading";

type Props = {
    open: boolean;
    handleClose: () => void;
    action: () => void;
    loading: boolean;
};

const ConfirmationModal = ({ open, handleClose, action, loading }: Props) => (
    <Modal
        open={open}
        onClose={handleClose}
        size="xs"
        backdropClassName="flex justify-center items-center"
    >
        <Modal.Header>
            <Modal.Title className="font-avenirBook">
                Confirm Action
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="text-center text-sm font-semibold">
                <h3>Are you sure you want to continue with this action?</h3>
            </div>
            <div className="flex justify-between mx-auto mt-4 text-white font-semibold px-6">
                <CustomButton
                    className="bg-green-600 px-6 py-2 hover:bg-green-500"
                    onClick={action}
                >
                    {loading ? <Loading size="xs" /> : "Yes, I accept"}
                </CustomButton>
                <CustomButton
                    className="bg-red-600 px-6 py-2 hover:bg-red-500"
                    onClick={handleClose}
                >
                    Cancel
                </CustomButton>
            </div>
        </Modal.Body>
    </Modal>
);

export default ConfirmationModal;
