import axios from "../../../axios";

export const getTransactionsApi = async (
    pageLimit: any,
    pageNumber: any,
    query: any
) => {
    try {
        let url = `/transactions?pageNumber=${pageNumber}&pageLimit=${pageLimit}`;
        if (query) {
            Object.keys(query).forEach(key => {
                url += `&${key}=${query[key]}`;
            });
        }
        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

/**
 * Description - Reverse Pending Transactions
 * @param {string} transactionId?:string
 * @returns {any}
 */
export const reversePendingTransactionsApi = async (
    transactionId?: string
): Promise<any> => {
    const url = `/data/pendingtransactions/reverse`;
    try {
        const res = transactionId
            ? await axios.post(url)
            : await axios.post(url, { transactionId });
        return res.data;
    } catch (error) {
        throw error;
    }
};
