import React from "react";
import { FaPhone } from "react-icons/fa";

type Props = {
    placeholder?: string;
    name?: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
    onIconClick?: () => void;
    error?: string;
    type?: string;
    disabled?: boolean;
    label?: string;
    required?: boolean;
};

const FloatingInputAddon = ({
    placeholder,
    name,
    value,
    onChange,
    onFocus,
    error,
    type,
    disabled,
    label,
    required,
    onIconClick,
}: Props) => (
    <div className="my-4 font-avenirBook">
        <div className="relative w-full">
            <input
                type={type}
                className={`w-full px-2 py-2 text-sm text-gray-700 bg-transparent border-0 border-b-[2px] border-[#101828]/10 ppearance-none placholder:text-gray-500 placeholder:text-sm focus:outline-none focus:ring-0 focus:border-blue-300 peer ${
                    error && "border-red-300"
                }`}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                disabled={disabled}
                required
            />
            <label
                htmlFor={name}
                className="absolute text-sm text-gray-500 pl-2 duration-300 transform -translate-y-6 scale-75 top-1 left-0 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-500  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-10"
            >
                {label || "Input Label"}
                {required && <span className="text-red-600 ">*</span>}
            </label>
            <a href={`tel:${value}`}>
                <FaPhone
                    className="absolute text-[#013653] right-2 bottom-3 cursor-pointer text-sm"
                    onClick={onIconClick}
                />
            </a>
        </div>
    </div>
);

FloatingInputAddon.defaultProps = {
    placeholder: "",
    name: "", // name of the input
    value: "", // default value
    onChange: () => {}, // onChange callback
    onFocus: () => {}, // onFocus callback
    onIconClick: () => {},
    error: "", // error message (if any)
    disabled: false, // whether the input is disabled
    type: "text", // error message (if any)
    required: false, // whether the input is required
    label: "Input Label", // label of the input
};

export default FloatingInputAddon;
