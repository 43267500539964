import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button } from "rsuite";
import { BiX } from "react-icons/bi";
import PageHelmet from "../../../components/blocks/PageHelmet";
// import TableSubHeader from "../../../components/blocks/TableSubHeader";
// import { CustomButton } from "../../../components/elements/Button";
import Loading from "../../../components/elements/Loading/Loading";
import { getCommunitiesApi } from "../../../redux/slice/communities/communities.api";
import formatDate from "../../../utils/formatDate";
import AddCommunity from "./AddCommunity";
import CommunityDetail from "./CommunityDetail";
import PaginationFilter from "../../../components/elements/paginationFilter";
import CustomLoader from "../../../components/elements/Loading/CustomLoader";

const columns = [
    {
        name: "Community Name",
        selector: (row: any) => row.displayCommunityName,
    },
    {
        name: "Community Category",
        selector: (row: any) => row.communityCategory,
    },
    {
        name: "Community Owner",
        selector: (row: any) =>
            row.communityOwnerUserRecord !== undefined
                ? `${row.communityOwnerUserRecord?.first_name} ${row.communityOwnerUserRecord?.last_name}`
                : "-",
    },
    {
        name: "Date Created",
        selector: (row: any) => formatDate(row.createdAt),
    },
];

const Communities = () => {
    const [communities, setCommunities] = useState([]);
    const [filteredCommunities, setFilteredCommunities] = useState<any>([]);
    const [showCreate, setShowCreate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [selectedCommunity, setSelectedCommunity] = useState({});

    // const [queryObject, setQueryObject] = useState({});
    // const [showFilter, setShowFilter] = useState(false);

    const [filterSearch, setFilterSearch] = useState("");
    const [keyword, setKeyword] = useState("");

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [pagination, setPagination] = useState({
        perPage: 5000,
        page: 1,
    });

    const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [maxRow, setMaxRow] = useState(50);

    const fetchCommunities = async (
        pageLimit?: number,
        // pageNumber?: number,
        query?: any
    ) => {
        setLoading(true);
        try {
            const res = await getCommunitiesApi();
            // pageLimit || pagination.perPage,
            // pagination.page,
            // query
            setCommunities(res.communities);
            setLoading(false);
            return res;
        } catch (error) {
            setLoading(false);
            return null;
        }
    };

    // const handleFilterChoice = () => {
    //     setShowFilter(true);
    // };

    const handleShowCommunityDetail = (row: any) => {
        setSelectedCommunity({
            displayCommunityName: row.displayCommunityName,
            communityCategory: row.hasOwnProperty("communityCategory")
                ? row.communityCategory
                : {},
            communityName: row.communityName,
            communityAdminsUserRecords:
                row.communityAdmins.length > 0
                    ? row.communityAdminsUserRecords
                    : [],
            createdAt: row.createdAt,
            id: row["_id"],
        });
        setShowDetail(true);
    };

    const handleHideCommnityDetail = () => {
        setShowDetail(false);
        // fetchCommunities(pagination.perPage);
    };

    const handleShowCreate = () => {
        setShowCreate(true);
    };

    const handleHideCreate = () => {
        setShowCreate(false);
        fetchCommunities(pagination.perPage);
    };

    const getPageData = (current: number, paginationLength: number) => {
        const startIndex = (current - 1) * paginationLength;
        const endIndex = startIndex + paginationLength;

        return communities.slice(startIndex, endIndex);
    };

    const handlePagination = (value: number) => {
        setCurrentPage(value);

        const pageData = getPageData(value, maxRow);
        setFilteredCommunities(pageData);
    };

    const onNext = async () => {
        const newPage = pagination.perPage + pagination.perPage;
        await fetchCommunities(newPage);
        setPagination({ ...pagination, perPage: newPage });
    };

    // const filterCommunities = (query: any) => {
    //     setShowFilter(false);

    //     if (Object.entries(query).length === 0) {
    //         fetchCommunities(pagination.perPage);
    //         setQueryObject({});
    //     } else {
    //         const res = Object.entries(query).filter(e => e[1] !== "");
    //         fetchCommunities(pagination.perPage, Object.fromEntries(res));
    //         setQueryObject(Object.fromEntries(res));
    //     }
    // };

    useEffect(() => {
        setLoading(true);
        setFilteredCommunities(communities);
        if (keyword == null) {
            return;
        }
        // Filter the rows array to find rows that match the keyword
        const searchResult = communities.filter((row: any) =>
            row.communityName.toLowerCase().includes(keyword.toLowerCase())
        );

        setFilteredCommunities(searchResult);
        setLoading(false);
    }, [keyword]);

    useEffect(() => {
        fetchCommunities();
    }, []);
    useEffect(() => {
        setFilteredCommunities(communities);
    }, [communities]);

    return (
        <>
            <PageHelmet title="Communities" />
            <div className="flex items-center justify-between w-full p-0">
                <h1 className="text-xs font-bold sm:text-base">
                    Community Management
                </h1>
                <div>
                    <Button
                        className="bg-[#006399] py-1.5 px-4 text-white hover:bg-[#0073b3] hover:text-white border-0 text-xs sm:text-sm"
                        onClick={handleShowCreate}
                    >
                        Create Community
                    </Button>
                </div>
            </div>
            <div className="group flex justify-between items-center my-2 border border-gray-400 rounded p-1 md:w-1/2 ">
                <input
                    type="text"
                    className="focus:border-0 border-0 outline-none grow pl-2"
                    value={filterSearch}
                    onInput={value =>
                        setFilterSearch(value.currentTarget.value)
                    }
                    placeholder="Search Communities by Name"
                />
                <button
                    type="button"
                    onClick={() => {
                        setKeyword("");
                        setFilterSearch("");
                    }}
                    className="group-focus-within:flex hidden mx-2 p-2 bg-slate-100 hover:bg-slate-200 text-black rounded-full"
                >
                    <BiX className="w-4 h-4" />
                </button>
                <button
                    type="button"
                    onClick={() => setKeyword(filterSearch)}
                    className="px-6 py-1.5 bg-lola-blue hover:bg-lola-blue/90 text-white rounded"
                >
                    Search
                </button>
            </div>
            <div className="w-full overflow-y-auto max-h-[500px]">
                <DataTable
                    columns={columns}
                    data={filteredCommunities}
                    highlightOnHover
                    responsive
                    pointerOnHover
                    subHeader
                    subHeaderWrap
                    // subHeaderComponent={
                    //     <TableSubHeader
                    //         handleFilter={handleFilterChoice}
                    //         queryObject={queryObject}
                    //         filterTransaction={filterCommunities}
                    //     />
                    // }
                    progressPending={loading}
                    progressComponent={<Loading />}
                    onRowClicked={handleShowCommunityDetail}
                />
            </div>

            <div className="flex flex-col w-full">
                {!loading && (
                    <PaginationFilter
                        currentPage={currentPage}
                        totalPages={Math.ceil(
                            filteredCommunities.length / maxRow
                        )}
                        onPageChange={handlePagination}
                    />
                )}
            </div>
            <div className="flex flex-col items-center mt-2 text-xs text-white">
                <button
                    disabled={loading}
                    type="button"
                    className="px-6 py-4 bg-neutral-700 rounded-full w-fit flex gap-2 items-center"
                    onClick={onNext}
                >
                    {loading && <CustomLoader size="base" />}
                    Load More
                </button>
            </div>
            {showCreate && <AddCommunity open handleClose={handleHideCreate} />}
            {showDetail && (
                <CommunityDetail
                    open
                    toggleModal={handleHideCommnityDetail}
                    community={selectedCommunity}
                />
            )}
        </>
    );
};

export default Communities;
