import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: {} as object,
    error: {} as object,
    loading: false as boolean,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        isPending: (state: any) => {
            state.loading = true;
        },
        isRejected: (state: any, action: any) => {
            state.loading = false;
            state.error = action.payload;
        },
        isFulfilled: (state: any, action: any) => {
            state.loading = false;
            state.user = action.payload;
        },
    },
});

const { actions: authActions, reducer: authReducer } = authSlice;
export { authActions, authReducer };
