/* eslint-disable no-console */
const isLocal = window.location.host.includes("localhost");

const debug = {
    log: (...args: any[]) => {
        if (isLocal) {
            console.log(...args);
        }
    },
    error: (...args: any[]) => {
        if (isLocal) {
            console.error(...args);
        }
    },
    warn: (...args: any[]) => {
        if (isLocal) {
            console.warn(...args);
        }
    },
    // Add more methods as needed
    customMethod: (...args: any[]) => {
        if (isLocal) {
            console.log("Custom method:", ...args);
        }
    },
};

export default debug;
