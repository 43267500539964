import React from "react";
import { Loader } from "rsuite";

const defaultProps = {
    size: "md",
    speed: "normal",
    backdrop: false,
    center: false,
    content: "",
};

type LoadingProps = {
    size?: "xs" | "sm" | "md" | "lg";
    speed?: "normal" | "fast" | "slow";
    backdrop?: boolean;
    center?: boolean;
    content?: string;
} & typeof defaultProps;

const Loading = ({ size, speed, backdrop, center, content }: LoadingProps) => (
    <Loader
        size={size}
        speed={speed}
        backdrop={backdrop}
        content={content}
        center={center}
    />
);

Loading.defaultProps = defaultProps;

export default Loading;
