import axios from "../../../axios";

export const getBusinessReportApi = async (query?: any) => {
    try {
        let url;
        if (query) {
            const { fromDate, toDate } = query;
            url = `/report/business?fromDate=${fromDate}&toDate=${toDate}`;
        } else {
            url = "/report/business";
        }
        const res = await axios.get(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getCommunitySuccessApi = async (query?: any) => {
    try {
        let url;
        if (query) {
            const { fromDate, toDate } = query;
            url = `/report/communitysuccess?fromDate=${fromDate}&toDate=${toDate}`;
        } else {
            url = "/report/communitysuccess";
        }
        const res = await axios.get(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const teamLevelReportApi = async (query?: any) => {
    try {
        let url;

        if (query) {
            const { fromDate, toDate } = query;
            url = `/report/business?teamView=true&fromDate=${fromDate}&toDate=${toDate}`;
        } else {
            url = "/report/business?teamView=true";
        }

        const res = await axios.get(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};
