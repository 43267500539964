import { validateAll } from "indicative/validator";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "rsuite";
import { ErrorToast, SuccessToast } from "../../../components/blocks/Alert";
import { DrawerButton } from "../../../components/elements/Button";
import FloatingLabelInput from "../../../components/elements/Inputs/FloatingLabelInput";
import FloatingLabelSelectInput from "../../../components/elements/Inputs/FloatingLabelSelectInput";
import Loading from "../../../components/elements/Loading/Loading";
import { getCommunitiesApi } from "../../../redux/slice/communities/communities.api";
import { createCustomerApi } from "../../../redux/slice/customers/customers.api";

type Props = {
    open: boolean;
    handleClose: () => void;
};

const AddCustomer = ({ open, handleClose }: Props) => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        communityId: "",
    });
    const [communities, setCommunities] = useState([]);
    const [error, setError] = useState<any>("");
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const processCreate = async (data: any) => {
        setLoading(true);
        const payload = {
            customers: [data],
        };
        try {
            const res = await createCustomerApi(payload);
            if (res.successful) {
                SuccessToast("Customer successfully created");
                handleClose();
            }
            if (!res.successful) {
                ErrorToast(res.unsuccessfulOutcomes[0].reason);
            }
            if (!res.successful && res.message) {
                ErrorToast(res.message);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        const rules = {
            firstName: "required",
            lastName: "required",
            phoneNumber: "required",
            communityId: "required",
        };

        const messages = {
            "firstName.required": "Firstname is required",
            "lastName.required": "Lastname is required",
            "phoneNumber.required": "Phone Number is required",
            "communityId.required": "Community is required",
        };

        validateAll(formData, rules, messages)
            .then(() => processCreate(formData))
            .catch((errors: any) => {
                const formattedErrors = {} as any;
                errors.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setError(formattedErrors);
            });
    };

    const fetchCommunities = async () => {
        setLoading(true);
        try {
            const res = await getCommunitiesApi();
            const communityList = res.map((e: any) => ({
                name: e.displayCommunityName,
                value: e["_id"],
            }));

            setCommunities(communityList);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCommunities();
    }, []);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size="xs"
            backdropClassName="flex justify-center items-center"
        >
            <Modal.Header>
                <Modal.Title className="font-avenirBook">
                    Create Customer
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="pb-1">
                    <FloatingLabelInput
                        type="text"
                        label="First Name"
                        onChange={e => handleInputChange(e)}
                        name="firstName"
                        value={formData.firstName}
                        error={error.firstName}
                    />
                </div>
                <div className="pb-1">
                    <FloatingLabelInput
                        type="text"
                        label="Last Name"
                        onChange={e => handleInputChange(e)}
                        name="lastName"
                        value={formData.lastName}
                        error={error.lastName}
                    />
                </div>
                <div className="pb-1">
                    <FloatingLabelInput
                        type="tel"
                        label="Phone Number"
                        onChange={e => handleInputChange(e)}
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        error={error.phoneNumber}
                    />
                </div>
                <div className="">
                    <FloatingLabelSelectInput
                        options={communities}
                        label="Communities"
                        onChange={e => handleInputChange(e)}
                        name="communityId"
                        value={formData.communityId}
                        error={error.communityId}
                    />
                </div>
                <div className="flex flex-col justify-center">
                    <DrawerButton onClick={handleSubmit}>
                        {loading ? <Loading size="sm" /> : "Create Customer"}
                    </DrawerButton>
                    <Button
                        onClick={handleClose}
                        className="text-red-600 bg-slate-100 hover:text-red-600"
                    >
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddCustomer;
