import React, { useEffect, useId, useState } from "react";
import DataTable from "react-data-table-component";
import { CustomButton } from "../../../components/elements/Button";
import FloatingLabelInput from "../../../components/elements/Inputs/FloatingLabelInput";
import Loading from "../../../components/elements/Loading/Loading";
import {
    getBusinessReportApi,
    teamLevelReportApi,
} from "../../../redux/slice/reports/reports.api";
import ReportCards from "./ReportCards";
import ReportDetails from "./ReportDetails";

const columns = [
    {
        name: "Staff Name",
        selector: (row: any) => row.rowData["Staff Name"],
    },
    {
        name: "Staff Phone Number",
        selector: (row: any) => row.rowData["Staf Phone Number"],
    },
    {
        name: "No. Of Users Onboarded",
        selector: (row: any) => row.rowData["Total Number Of Users Onboarded"],
    },
    {
        name: "No. Of Invalid Accounts",
        selector: (row: any) =>
            row.rowData[
                "Total Number Of Users Considered Fake or Poor Accounts"
            ],
    },
    {
        name: "No. Of Valid Accounts",
        selector: (row: any) =>
            row.rowData["Total Number Of Users Considered To Be Valid"],
    },
    { name: " Team", selector: (row: any) => row.rowData.Team },
];

const teamColumns = [
    {
        name: "Staff Name",
        selector: (row: any) => row.rowData["Staff Name"],
    },
    {
        name: "Staff Phone Number",
        selector: (row: any) => row.rowData["Staf Phone Number"],
    },
    {
        name: "Team",
        selector: (row: any) => row.rowData["Team"],
    },
    {
        name: "No. Of Users Onboarded",
        selector: (row: any) => row.rowData["Total Number Of Users Onboarded"],
    },
    {
        name: "No. Of Invalid Accounts",
        selector: (row: any) =>
            row.rowData[
                "Total Number Of Users Considered Fake or Poor Accounts"
            ],
    },
    {
        name: "No. Of Valid Accounts",
        selector: (row: any) =>
            row.rowData["Total Number Of Users Considered To Be Valid"],
    },
];

const Reports = () => {
    const id = useId();
    const [loading, setLoading] = useState(false);
    const [reportCards, setReportCards] = useState([]);
    const [tableResult, setTableResult] = useState([]);
    const [teamBasedResultSetList, setTeamBasedResultSetList] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [subData, setSubData] = useState({});
    const [toggleReport, setToggleReport] = useState(false);
    const [dateOptions, setDateOptions] = useState<any>({
        fromDate: "2022-07-18T00:01",
        toDate: "2022-07-18T00:01",
    });

    const handleInputChange = (e: any) => {
        const today = new Date().toISOString().slice(0, 10);
        const { name, value } = e.target;

        if (value === "") {
            return setDateOptions({
                ...dateOptions,
                [name]: today,
            });
        }

        return setDateOptions({
            ...dateOptions,
            [name]: value,
        });
    };

    const fetchTeamViewReport = async (data?: any) => {
        setLoading(true);
        let payload;

        if (data) {
            payload = {
                fromDate: data.fromDate,
                toDate: data.toDate,
            };
        }

        try {
            const res = await teamLevelReportApi(payload);
            if (!res.successful) {
                setReportCards({ ...reportCards });
            }

            setTableResult([]);
            setDateOptions(res.options);
            setReportCards(res.cards);
            setTeamBasedResultSetList(res.teamBasedResultSetList);

            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const fetchReport = async (data?: any) => {
        setLoading(true);
        let payload;

        if (data) {
            payload = {
                fromDate: data.fromDate,
                toDate: data.toDate,
            };
        }

        try {
            const res = await getBusinessReportApi(payload);
            if (!res.successful) {
                setReportCards([]);
                setTableResult([]);
            }
            setReportCards(res.cards);
            setTableResult(res.tablesResultSet);
            setDateOptions(res.options);

            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const handleToggleReport = () => {
        if (!toggleReport) {
            setToggleReport(true);
            fetchTeamViewReport();
        }

        if (toggleReport) {
            setToggleReport(false);
            fetchReport();
        }
    };

    const handleFilter = () => {
        if (toggleReport) fetchTeamViewReport(dateOptions);
        if (!toggleReport) fetchReport(dateOptions);
    };

    const handleShowDetail = (row: any) => {
        setSubData(row.subData);
        setShowDetail(true);
    };

    const handleHideDetail = () => {
        setShowDetail(false);
    };

    useEffect(() => {
        fetchReport();
    }, []);

    return (
        <>
            <div className="flex justify-end">
                <CustomButton
                    buttonStyle="px-6 py-2 h-fit bg-[#013653] hover:bg-[#02527e] sm:self-center text-white font-semibold"
                    onClick={handleToggleReport}
                >
                    {toggleReport ? "Onboarders Report" : "Teams Report"}
                </CustomButton>
            </div>
            <div className="flex flex-col mb-10 sm:space-x-4 sm:flex-row">
                <div className="w-full sm:w-4/12">
                    <FloatingLabelInput
                        type="datetime-local"
                        label="From (Start Date)"
                        name="fromDate"
                        value={dateOptions.fromDate}
                        min="2022-07-18T00:00"
                        max={new Date().toJSON().slice(0, 16)}
                        onChange={e => handleInputChange(e)}
                    />
                </div>
                <div className="w-full sm:w-4/12">
                    <FloatingLabelInput
                        type="datetime-local"
                        label="To (End Date)"
                        name="toDate"
                        value={dateOptions.toDate}
                        min="2022-07-18T00:00"
                        max={new Date().toJSON().slice(0, 16)}
                        onChange={e => handleInputChange(e)}
                    />
                </div>
                <CustomButton
                    buttonStyle="px-6 py-2 h-fit bg-blue-400 hover:bg-blue-500 sm:self-center text-white font-semibold"
                    onClick={handleFilter}
                >
                    Filter
                </CustomButton>
            </div>
            {loading ? (
                <div className="text-center">
                    <Loading size="lg" />
                </div>
            ) : (
                <>
                    <div className="flex flex-wrap items-center w-full">
                        {reportCards.length !== 0 ? (
                            reportCards.map((reportCard: any) => (
                                <ReportCards
                                    loading={loading}
                                    title={reportCard.cardTitle}
                                    value={reportCard.cardValue}
                                    key={`${id}_${reportCard.cardTitle}`}
                                />
                            ))
                        ) : (
                            <div className="text-xl font-semibold text-center">
                                No records found
                            </div>
                        )}
                    </div>

                    {toggleReport ? (
                        <div className="w-full my-6">
                            {teamBasedResultSetList.length !== 0 &&
                                teamBasedResultSetList.map(
                                    (teamResult: any) => (
                                        <>
                                            <div
                                                className="flex justify-between mb-4"
                                                key={`${id}_${teamResult.teamSpec.teamId}`}
                                            >
                                                <h1 className="text-[#F75500] text-base font-semibold">
                                                    {
                                                        teamResult.teamSpec
                                                            .teamName
                                                    }
                                                </h1>
                                                <p>Team Detail</p>
                                            </div>
                                            <div className="my-4">
                                                <DataTable
                                                    columns={teamColumns}
                                                    data={
                                                        teamResult.membersPerformance
                                                    }
                                                    onRowClicked={
                                                        handleShowDetail
                                                    }
                                                />
                                            </div>
                                        </>
                                    )
                                )}
                        </div>
                    ) : (
                        <div className="w-full my-6">
                            {tableResult.length !== 0 &&
                                tableResult.map((result: any) => (
                                    <div
                                        className="mb-4"
                                        key={`${id}_${result.tableTitle}`}
                                    >
                                        <h1 className="text-[#F75500] text-base font-semibold">
                                            {result.tableTitle}
                                        </h1>
                                        <div className="mt-4">
                                            <DataTable
                                                data={result.tableContent}
                                                onRowClicked={handleShowDetail}
                                                columns={columns}
                                            />
                                        </div>
                                    </div>
                                ))}
                        </div>
                    )}
                </>
            )}
            {showDetail && (
                <ReportDetails
                    open
                    toggleModal={handleHideDetail}
                    subData={subData}
                />
            )}
        </>
    );
};

export default Reports;
