import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import PageHelmet from "../../../components/blocks/PageHelmet";
import { CustomButton } from "../../../components/elements/Button";
import Loading from "../../../components/elements/Loading/Loading";
import { getAllStaffApi } from "../../../redux/slice/staff/staff.api";
import { getTeamsApi } from "../../../redux/slice/teams/teams.api";
import formatDate from "../../../utils/formatDate";
import CreateTeams from "./CreateTeams";

const columns = [
    { name: "Team Name", selector: (row: any) => row.teamName },
    {
        name: "Team Description",
        selector: (row: any) => row.teamDescription,
    },
    {
        name: "Date Created",
        selector: (row: any) => formatDate(row.createdAt),
    },
];

const Teams = () => {
    const [teams, setTeams] = useState([]);
    const [staff, setStaff] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showCreate, setShowCreate] = useState(false);

    const handleShowCreate = () => {
        setShowCreate(true);
    };

    const handleCloseCreate = () => {
        setShowCreate(false);
    };

    const fetchStaff = async () => {
        try {
            const res = await getAllStaffApi();
            const staffObj = res.staffsResultSet.map((e: any) => ({
                name: `${e.userRecord.first_name} ${e.userRecord.last_name}`,
                value: e.userId,
            }));
            setStaff(staffObj);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchTeams = async () => {
        setLoading(true);
        try {
            const res = await getTeamsApi();
            if (res.successful) setTeams(res.teamList);
            await fetchStaff();
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTeams();
    }, []);

    return (
        <>
            <PageHelmet title="Teams" />
            <div className="flex items-center justify-between w-full p-0 mb-8">
                <h1 className="text-xs font-bold sm:text-base">
                    Teams Management
                </h1>
                <div>
                    <CustomButton
                        className="bg-[#006399] py-1.5 px-4 text-white hover:bg-[#0073b3] hover:text-white border-0 text-xs sm:text-sm"
                        onClick={handleShowCreate}
                    >
                        Create Team
                    </CustomButton>
                </div>
            </div>
            <div className="w-full">
                <DataTable
                    columns={columns}
                    data={teams}
                    highlightOnHover
                    responsive
                    pointerOnHover
                    progressPending={loading}
                    progressComponent={<Loading />}
                />
            </div>
            {showCreate && (
                <CreateTeams
                    open
                    handleClose={handleCloseCreate}
                    staff={staff}
                />
            )}
        </>
    );
};

export default Teams;
