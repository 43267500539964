import React, { useRef, useEffect } from "react";

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (pageNumber: number) => void;
}

const PaginationFilter: React.FC<PaginationProps> = ({
    currentPage,
    totalPages,
    onPageChange,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const targetRef = useRef<HTMLLIElement>(null);

    const renderPageNumbers = () =>
        Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li
                ref={pageNumber === currentPage ? targetRef : null}
                key={pageNumber}
                className={`cursor-pointer mx-1 p-3 rounded ${
                    pageNumber === currentPage
                        ? "bg-blue-500 text-white"
                        : "text-black"
                }`}
                onClick={() => onPageChange(pageNumber)}
            >
                {pageNumber}
            </li>
        ));

    const scrollToTarget = () => {
        if (!targetRef.current) return;

        targetRef.current?.scrollIntoView({
            behavior: "smooth", // Smooth scrolling
            block: "start", // Align to the start of the container
        });
    };

    useEffect(() => {
        if (!currentPage) return;
        scrollToTarget();
    }, [currentPage]);

    return (
        <nav className="flex flex-col">
            <ul className="grid grid-cols-12 gap-1 py-4 w-full">
                <li className="col-span-1">
                    <button
                        type="button"
                        className={`p-3 rounded ${
                            currentPage > 1
                                ? "font-semibold bg-neutral-100"
                                : "pointer-events-none opacity-50"
                        }`}
                        onClick={() => onPageChange(currentPage - 1)}
                    >
                        Previous
                    </button>
                </li>
                <div
                    ref={containerRef}
                    className="flex items-center col-span-10 overflow-x-auto"
                >
                    {renderPageNumbers()}
                </div>
                <li className="col-span-1">
                    <button
                        type="button"
                        className={`p-3 rounded ${
                            currentPage < totalPages
                                ? "font-semibold bg-neutral-100"
                                : "pointer-events-none opacity-50 "
                        }`}
                        onClick={() => onPageChange(currentPage + 1)}
                    >
                        Next
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default PaginationFilter;
