/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useId } from "react";
import { useSelector } from "react-redux";
import formatDate from "../../../utils/formatDate";
import formatWord from "../../../utils/formatWord";
import numberFormat from "../../../utils/numberFormat";

import LolaImgMin from "../../../assets/images/logo/lola-logo.png";

/* eslint-disable react/react-in-jsx-scope */
// const { Column, HeaderCell, Cell } = Table;

const Row = ({ title, value }: { title: string; value: any }) => (
    <div className="flex items-center justify-between py-2 text-xs font-semibold sm:text-sm">
        <div className="text-left">{title}:</div>
        <div className="text-right">{value}</div>
    </div>
);

type Props = {
    forwardRef?: React.RefObject<HTMLDivElement>;
    data?: any;
    dates?: { from: string; to: string };
    communityInformation?: any;
};

const StatementOfAccount = ({
    forwardRef,
    data,
    dates,
    communityInformation,
}: Props) => {
    const id = useId();
    // const {
    //     community: { communityInformation },
    // } = useSelector((state: any) => state.community);

    console.log("original: ", data);

    const subData = Object.entries(data?.transactions).reverse();
    // console.log('the reveresed: ', subData[0][1])

    return (
        <section className="flex flex-col w-full overflow-auto">
            <div
                className="flex flex-col min-w-[1240px] w-full p-3"
                ref={forwardRef}
            >
                <div className="flex flex-col items-center">
                    <img
                        src={LolaImgMin}
                        alt="alt"
                        className="w-auto h-16 object-contain"
                    />
                </div>
                <header>
                    <h5 className="text-xl font-semibold uppercase text-center mt-3">
                        Statement of Account
                    </h5>
                </header>
                <main>
                    <div className="w-full my-8 border-black border-y-4 ">
                        <h4 className="text-xl font-bold">
                            {/* Dicentflo Resources Community */}
                            {communityInformation?.communityName || "N/A"}
                        </h4>
                        <p className="py-2 text-sm flex gap-x-4">
                            <span className="font-semibold">
                                From : {dates?.from}
                            </span>
                            <span className="font-semibold">
                                To : {dates?.to}
                            </span>
                        </p>
                    </div>
                    <div className="w-5/12 ">
                        <h5 className="p-2 font-semibold bg-gray-200">
                            Account Summary
                        </h5>
                        <section className="p-2">
                            {Object.entries({
                                "Opening Balance": data.openingBalance,
                                "Total Debit": data.debitTotal,
                                "Total Credit": data.creditTotal,
                                "Closing Balance": data.closingBalance,
                            }).map(d => (
                                <Row
                                    title={formatWord(d[0])}
                                    value={`₦ ${numberFormat(d[1])}`}
                                    key={id}
                                />
                            ))}
                            <div className="">
                                <Row
                                    title="Number of Transactions"
                                    value={`${data.numberOfTransactions}`}
                                />
                            </div>
                            <div className="pt-1 mt-4 border-t-2 border-black">
                                <Row
                                    title="Total"
                                    value={`₦ ${numberFormat(
                                        data.totalVolume
                                    )}`}
                                />
                            </div>
                        </section>
                    </div>
                    <section className="my-8">
                        <table className="w-full text-left">
                            <thead className="bg-[#101828] text-white h-12">
                                <tr>
                                    {[
                                        { id: 0, title: "Date" },
                                        { id: 1, title: "Reference" },
                                        { id: 2, title: "Description" },
                                        { id: 3, title: "Credit(₦)" },
                                        { id: 4, title: "Debit(₦)" },
                                        { id: 5, title: "Balance" },
                                    ].map(d => (
                                        <th key={id} className="px-2">
                                            {d.title}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {subData.map((transaction: any) => (
                                    <tr
                                        key={transaction[1].id}
                                        className="h-16 border-b-2 pr-2 !important text-sm"
                                    >
                                        <td className="border-2 px-2 break-words text-xs">
                                            {formatDate(
                                                transaction[1].date_created
                                            )}
                                        </td>
                                        <td className="border-2 w-2/12 px-2 break-words text-xs">
                                            {
                                                transaction[1]
                                                    .transactionReference
                                            }
                                        </td>
                                        <td className="border-2 w-5/12 px-2 break-words text-xs">{`${transaction[1].description}`}</td>
                                        <td className="border-2 w-1/12 px-2 break-words font-semibold">
                                            {transaction[1].type === "credit"
                                                ? numberFormat(
                                                      transaction[1].amount
                                                  )
                                                : null}
                                        </td>
                                        <td className="border-2 w-1/12 px-2 break-words font-semibold">
                                            {transaction[1].type === "debit"
                                                ? numberFormat(
                                                      transaction[1].amount
                                                  )
                                                : null}
                                        </td>
                                        <td className="border-2 px-2 break-words font-semibold w-fit">
                                            {numberFormat(
                                                transaction[1].balanceAfter
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>
                </main>
            </div>
        </section>
    );
};

export default StatementOfAccount;
