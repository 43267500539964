import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button } from "rsuite";
import PageHelmet from "../../../components/blocks/PageHelmet";
import Loading from "../../../components/elements/Loading/Loading";
import { getAllPosAgentsApi } from "../../../redux/slice/posagents/posagents.api";
import formatDate from "../../../utils/formatDate";
import AddAgent from "./AddAgent";
import AgentDetail from "./AgentDetail";
import PaginationFilter from "../../../components/elements/paginationFilter";

const columns = [
    {
        name: "Fullname",
        selector: (row: any) => `${row.fullName}`,
        grow: 1,
    },
    {
        name: "Phone Number",
        selector: (row: any) => row.phoneNumber,
    },
    {
        name: "Date Created",
        selector: (row: any) => formatDate(row.createdAt),
    },
];

const PosAgents = () => {
    const [loading, setLoading] = useState(false);
    const [agents, setAgents] = useState([]);
    const [filteredAgents, setFilteredAgents] = useState<any>([]);
    const [showCreate, setShowCreate] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState({});
    const [showDetail, setShowDetail] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [maxRow, setMaxRow] = useState(6);

    const fetchAgents = async (query?: any) => {
        setLoading(true);
        try {
            const res = await getAllPosAgentsApi(query);
            if (res.successful) setAgents(res.posAgentsList);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleShowCreate = () => {
        setShowCreate(true);
    };

    const handleCloseCreate = () => {
        setShowCreate(false);
        fetchAgents();
    };

    const handleShowAgentDetail = (row: any) => {
        setSelectedAgent({
            fullName: row.fullName,
            phoneNumber: row.phoneNumber,
            createdAt: formatDate(row.createdAt),
            id: row["_id"],
        });
        setShowDetail(true);
    };

    const handleHideAgentDetail = () => {
        setShowDetail(false);
        fetchAgents();
    };

    const getPageData = (current: number, paginationLength: number) => {
        const startIndex = (current - 1) * paginationLength;
        const endIndex = startIndex + paginationLength;

        return agents.slice(startIndex, endIndex);
    };

    const handlePagination = (value: number) => {
        setCurrentPage(value);

        const pageData = getPageData(value, maxRow);
        setFilteredAgents(pageData);
    };

    useEffect(() => {
        fetchAgents();
    }, []);

    useEffect(() => {
        handlePagination(currentPage);
    }, [agents]);

    return (
        <>
            <PageHelmet title="POS Agents" />
            <div className="flex items-center justify-between w-full p-0 mb-4">
                <h1 className="text-xs font-bold sm:text-base">POS Agents</h1>
                <div>
                    <Button
                        className="bg-[#006399] py-1.5 px-4 text-white hover:bg-[#0073b3] hover:text-white border-0 text-xs sm:text-sm"
                        onClick={handleShowCreate}
                    >
                        Create Agent
                    </Button>
                </div>
            </div>
            <div className="w-full min-h-[50vh] overflow-y-auto">
                <DataTable
                    data={filteredAgents}
                    columns={columns}
                    highlightOnHover
                    responsive
                    pointerOnHover
                    subHeader
                    subHeaderWrap
                    progressPending={loading}
                    progressComponent={<Loading />}
                    onRowClicked={handleShowAgentDetail}
                />
            </div>
            <div className="flex justify-end">
                <PaginationFilter
                    currentPage={currentPage}
                    totalPages={Math.ceil(agents.length / maxRow)}
                    onPageChange={handlePagination}
                />
            </div>
            {showCreate && <AddAgent open handleClose={handleCloseCreate} />}
            {showDetail && (
                <AgentDetail
                    open
                    toggleModal={() => handleHideAgentDetail()}
                    agent={selectedAgent}
                />
            )}
        </>
    );
};

export default PosAgents;
