import React from "react";
import { Breadcrumb } from "rsuite";

type BCType = {
    id: number;
    link?: string;
    isActive?: boolean;
    title: string;
};

type Props = {
    data: BCType[];
};

const LBreadCrumb = ({ data }: Props) => (
    <Breadcrumb className="font-semibold">
        {data.map(({ id, link, isActive, title }) => (
            <Breadcrumb.Item
                href={link}
                active={isActive}
                key={id}
                className={`${
                    !isActive && "text-lola-orange hover:text-lola-orange/80"
                }`}
            >
                {title}
            </Breadcrumb.Item>
        ))}
    </Breadcrumb>
);

export default LBreadCrumb;
