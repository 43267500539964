import { validateAll } from "indicative/validator";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "rsuite";
import { SuccessToast } from "../../../components/elements/Alerts";
import { DrawerButton } from "../../../components/elements/Button";
import FloatingLabelSelectInput from "../../../components/elements/Inputs/FloatingLabelSelectInput";
import Loading from "../../../components/elements/Loading/Loading";
import { assignCustomerToPosAgentApi } from "../../../redux/slice/customers/customers.api";
import { getAllPosAgentsApi } from "../../../redux/slice/posagents/posagents.api";

type Props = {
    open: boolean;
    handleClose: () => void;
    customer: any;
};

const AssignAgent = ({ open, handleClose, customer }: Props) => {
    const [formData, setFormData] = useState({
        lolaCustomerUserId: customer.id,
        posAgentId: "",
        primaryAgent: "",
    });
    const [loading, setLoading] = useState(false);
    const [agents, setAgents] = useState([]);
    const [error, setError] = useState<any>("");
    const options = [
        { name: "Yes", value: "yes" },
        { name: "No", value: "no" },
    ];

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const processAssign = async (data: any) => {
        setLoading(true);

        try {
            const res = await assignCustomerToPosAgentApi(data);
            if (res.successful) {
                SuccessToast("POS Agent assigned successfully");
                handleClose();
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        const rules = {
            posAgentId: "required",
            primaryAgent: "required",
        };

        const messages = {
            "posAgentId.required": "Firstname is required",
            "primaryAgent.required": "Lastname is required",
        };

        validateAll(formData, rules, messages)
            .then(() => processAssign(formData))
            .catch((errors: any) => {
                const formattedErrors = {} as any;
                errors.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setError(formattedErrors);
            });
    };

    const fetchAgents = async (query?: any) => {
        setLoading(true);

        try {
            const res = await getAllPosAgentsApi(query);

            if (res.successful) {
                const result = res.posAgentsList.map((pos: any) => ({
                    name: pos.fullName,
                    value: pos["_id"],
                }));

                setAgents(result);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAgents();
    }, []);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size="xs"
            backdropClassName="flex justify-center items-center"
        >
            <Modal.Header>
                <Modal.Title className="font-avenirBook">
                    Assign POS Agent
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="pb-1">
                        <FloatingLabelSelectInput
                            options={agents}
                            label="POS Agent"
                            onChange={e => handleInputChange(e)}
                            name="posAgentId"
                            value={formData.posAgentId}
                            error={error.posAgentId}
                        />
                    </div>
                    <div className="pb-1">
                        <FloatingLabelSelectInput
                            options={options}
                            label="Is Primary Agent"
                            onChange={e => handleInputChange(e)}
                            name="primaryAgent"
                            value={formData.primaryAgent}
                            error={error.primaryAgent}
                        />
                    </div>
                    <div className="flex flex-col justify-center">
                        <DrawerButton onClick={handleSubmit}>
                            {loading ? <Loading size="sm" /> : "Assign Agent"}
                        </DrawerButton>
                        <Button
                            onClick={handleClose}
                            className="text-red-600 bg-slate-100 hover:text-red-600"
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default AssignAgent;
