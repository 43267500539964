import axios from "../../../axios";

export const syncUserDataApi = async () => {
    try {
        const url = "/data/userseed";
        const res = await axios.post(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const syncTransactionDataApi = async () => {
    try {
        const url = "/data/transactionsseed";
        const res = await axios.post(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};
