/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import { InfoIcon } from "lucide-react";
import { Modal, SelectPicker } from "rsuite";
import { z } from "zod";
import {
    createBucketApi,
    getActivitiesApi,
} from "../../../redux/slice/monitor/monitor.api";
import { SuccessToast, ErrorToast } from "../../../components/blocks/Alert";

const BucketSchema = z.object({
    bucketName: z.string().min(1, "Name is required"),
    activityId: z.string().min(1, "Activity is required"),
    monitoringPeriod: z
        .number()
        .gt(0, { message: "Number must be greater than 1" })
        .optional(),
});

type BracketFormData = z.infer<typeof BucketSchema>;

type Props = {
    open: boolean;
    handleClose: () => void;
};

const AddBucket = ({ open, handleClose }: Props) => {
    const [formData, setFormData] = useState<BracketFormData>({
        bucketName: "",
        activityId: "",
        monitoringPeriod: undefined,
    });

    const [activities, setActivities] = useState<any[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<any | null>(null);

    useEffect(() => {
        const fetchActivities = async () => {
            try {
                const { data } = await getActivitiesApi();
                console.log(data);
                if (data?.data) {
                    setActivities(data.data);
                }
            } catch (error) {
                console.error("Error fetching activities:", error);
            }
        };
        fetchActivities();
    }, []);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const validatedData = BucketSchema.parse(formData);
            const response = await createBucketApi(validatedData);
            SuccessToast("Bucket created successfully:");
            console.log("Bucket created successfully:", response);
            handleClose();
            setFormData({
                bucketName: "",
                activityId: "",
                monitoringPeriod: undefined,
            });
        } catch (error) {
            ErrorToast("Couldn't Complete... Something Went Wrong");
            console.error("Validation error:", error);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === "number" ? Number(value) : value,
        }));
    };

    const handleActivitySelect = (activityId: string) => {
        const activity = activities.find(act => act._id === activityId);
        setSelectedActivity(activity);
        setFormData(prev => ({
            ...prev,
            activityId,
            monitoringPeriod:
                activity?.dataType === "time_series"
                    ? prev.monitoringPeriod
                    : undefined,
        }));
    };

    return (
        <Modal open={open} onClose={handleClose} size="xs">
            <Modal.Header>
                <Modal.Title>Create New Bucket</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form
                    onSubmit={handleSubmit}
                    className="space-y-4 flex flex-col"
                >
                    <div>
                        <label
                            htmlFor="bucketName"
                            className="block font-medium mb-1"
                        >
                            Name
                        </label>
                        <input
                            id="bucketName"
                            name="bucketName"
                            value={formData.bucketName}
                            onChange={handleInputChange}
                            placeholder="Enter name"
                            className="w-full border border-gray-300 rounded p-2"
                        />
                    </div>

                    <div>
                        <label
                            htmlFor="activityId"
                            className="block font-medium mb-1"
                        >
                            What you want to Track
                        </label>
                        <SelectPicker
                            id="activityId"
                            data={activities.map(activity => ({
                                label: activity.activityName,
                                value: activity._id,
                            }))}
                            value={formData.activityId}
                            onChange={value => handleActivitySelect(value)}
                            placeholder="Select an activity"
                            className="w-full"
                        />
                    </div>

                    {selectedActivity && (
                        <div className="mb-4 border-t">
                            <p className="block text-gray-400 font-medium mb-1">
                                Description:
                            </p>
                            <div className="flex items-center gap-1 text-blue-300">
                                <InfoIcon className="w-4 h-4 " />
                                <p className="">
                                    {selectedActivity.description}
                                </p>
                            </div>
                        </div>
                    )}

                    {selectedActivity?.dataType === "time_series" && (
                        <div>
                            <label
                                htmlFor="monitoringPeriod"
                                className="block font-medium mb-1"
                            >
                                Monitoring Period (in days)
                            </label>
                            <input
                                id="monitoringPeriod"
                                name="monitoringPeriod"
                                type="number"
                                value={formData.monitoringPeriod ?? ""}
                                onChange={handleInputChange}
                                placeholder="Enter period"
                                className="w-full border border-gray-300 rounded p-2"
                            />
                        </div>
                    )}

                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded ml-auto"
                    >
                        Add Bucket
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default AddBucket;
