import React, { useId } from "react";
import DrawerPanel from "../../../components/elements/DrawerPanel/DrawerPanel";

type Props = {
    open: boolean;
    toggleModal: () => void;
    subData: any;
};

const ReportDetails = ({
    open,
    toggleModal,
    subData: { invalidUsersList, timeSeriesResultSet, validUsersList },
}: Props) => {
    const id = useId();

    return (
        <DrawerPanel
            open={open}
            handleClose={toggleModal}
            title="Performance Detail"
        >
            <div>
                <h3 className="my-2 text-base font-semibold text-[#f75500]">
                    No. of invalid Users:{" "}
                    <span className="text-[#0B4665]">
                        {invalidUsersList.length}
                    </span>
                </h3>
                <h3 className="my-2 text-base font-semibold text-[#f75500]">
                    No. of valid Users:{" "}
                    <span className="text-[#0B4665]">
                        {validUsersList.length}
                    </span>
                </h3>
                <div className="my-2">
                    <h3 className="text-base font-semibold text-[#f75500]">
                        Timeseries:
                    </h3>
                    {timeSeriesResultSet.map((result: any) => (
                        <div
                            className="pl-4 mt-2 mb-8"
                            key={`${id}_${result.id}`}
                        >
                            <h6 className="text-sm font-semibold text-[#0B4665]">
                                {result.day}
                            </h6>
                            {result.entries.map((entry: any) => (
                                <div className="pl-6 mt-4 mb-8">
                                    <h6 className="text-sm font-semibold text-[#f75500]">
                                        {entry.hour}
                                    </h6>
                                    {entry.entries.map((e: any) => (
                                        <>
                                            <div className="pl-8 my-4">
                                                <h6 className="text-sm font-semibold text-[#0B4665]">
                                                    {e.hourMinuteStamp}
                                                </h6>
                                                <p className="font-bold">{`${e.customerRecord.first_name} ${e.customerRecord.last_name}`}</p>
                                                {e.isAValidCustomer ? (
                                                    <p>
                                                        Customer is{" "}
                                                        <span className="font-semibold text-green-700">
                                                            valid
                                                        </span>
                                                    </p>
                                                ) : (
                                                    <p>
                                                        Customer is{" "}
                                                        <span className="font-semibold text-red-500">
                                                            invalid
                                                        </span>
                                                    </p>
                                                )}
                                            </div>
                                            <hr />
                                        </>
                                    ))}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </DrawerPanel>
    );
};

export default ReportDetails;
