/* eslint-disable import/prefer-default-export */
import axios from "../../../axios";

export const getActivitiesApi = async () => {
    try {
        const url = `/activities`;
        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getBucketsApi = async () => {
    try {
        const url = `/buckets`;
        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const createBucketApi = async (payload: any) => {
    try {
        const url = "/buckets";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const getBucketDetailsApi = async (bucketId: string) => {
    try {
        const url = `/buckets/${bucketId}/activities/users`;
        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

// Define the function to add users to a bucket
export const addUsersToBucketApi = async (
    bucketId: string,
    phoneNumbers: string[]
) => {
    try {
        const url = `buckets/users`;
        const response = await axios.post(url, {
            bucketId,
            phoneNumbers,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
