/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import { BiLoader, BiPencil } from "react-icons/bi";
import { FaTimes, FaCheck } from "react-icons/fa";
// import Loading from '../../Loading/Loading'
// import Button from '../../Button'

interface IField {
    title: string;
    initialValue: string;
    handleSubmit: (value: string) => any;
}

const EdittableField = ({ handleSubmit, title, initialValue }: IField) => {
    const [value, setValue] = useState(initialValue);
    const [edittable, setEdittable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async (pass: any) => {
        setIsLoading(true);
        try {
            await handleSubmit(pass).then(() => {
                setIsLoading(false);
                setEdittable(false);
                setValue(pass);
            });
        } catch (err: any) {
            setValue(initialValue);
        }
    };

    return (
        <section className="flex justify-between items-center py-2">
            <p className="text-xs sm:text-sm">{title}:</p>
            <div className="flex items-center focus-within:border-inherit focus-within:bg-gray-100 border border-transparent p-0.5 rounded group">
                <input
                    type="text"
                    disabled={!edittable || isLoading}
                    value={value}
                    // onBlur={() => { setEdittable(false); setValue(initialValue) }}
                    onInput={e => setValue(e.currentTarget.value)}
                    className="border-none outline-none text-right font-semibold focus-visible:ring-0 bg-transparent grow peer disabled:cursor-default"
                />
                <div className="flex items-center text-white gap-1 lg:group-hover:flex lg:peer-focus:flex ml-1">
                    <button
                        type="button"
                        disabled={isLoading}
                        onClick={() => setEdittable(!edittable)}
                        className={`p-1 rounded ${
                            !edittable
                                ? "bg-indigo-500 hover:bg-indigo-700"
                                : "bg-red-500 hover:bg-red-700"
                        }`}
                    >
                        {!edittable ? (
                            <BiPencil className="w-4 h-4" />
                        ) : (
                            <FaTimes className="w-4 h-4" />
                        )}
                    </button>
                    {edittable && (
                        <button
                            type="button"
                            disabled={isLoading}
                            onClick={() => handleClick(value)}
                            className="p-1 rounded bg-green-500 hover:bg-green-700"
                        >
                            {isLoading ? (
                                <BiLoader className="w-4 h-4 animate-spin" />
                            ) : (
                                <FaCheck className="w-4 h-4" />
                            )}
                        </button>
                    )}
                </div>
            </div>
        </section>
    );
};

export default EdittableField;
