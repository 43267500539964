import React, { useId, useState } from "react";
import { BiFilter, BiRefresh } from "react-icons/bi";
import { Tag, TagGroup } from "rsuite";

type Props = {
    handleFilter: () => void;
    handleRefresh: () => void;
    handleDateChange: (start: any, end: any) => void;
    queryObject: any;
    filterTransaction: any;
};

const FilterBtn = ({ handleFilter }: { handleFilter: () => void }) => (
    <button
        type="button"
        className="flex items-center border border-gray-200 hover:bg-gray-100 px-3 py-1.5"
        onClick={handleFilter}
    >
        <BiFilter className="w-5 h-5" />{" "}
        <span className="ml-1">Filter Transactions</span>
    </button>
);

const RefreshBtn = ({ handleRefresh }: { handleRefresh: () => void }) => (
    <button
        type="button"
        className="flex items-center border border-gray-200 hover:bg-gray-100 px-3 py-1.5"
        onClick={handleRefresh}
    >
        <BiRefresh className="w-5 h-5" /> <span className="ml-1">Refresh</span>
    </button>
);

const DateTimeFilter = ({
    handleDateChange,
}: {
    handleDateChange: (start: any, end: any) => void;
}) => {
    const [startDate, setStartDate] = useState<Date>(new Date("2022-01-02"));
    const [endDate, setEndDate] = useState<Date>(new Date(Date.now()));

    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedStartDate = new Date(e.target.value);
        setStartDate(selectedStartDate);
        // Call the handleDateChange function with the new start date and the current end date
        handleDateChange(
            selectedStartDate.toISOString().split("T")[0],
            endDate.toISOString().split("T")[0]
        );
    };

    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedEndDate = new Date(e.target.value);
        setEndDate(selectedEndDate);
        // Call the handleDateChange function with the current start date and the new end date
        handleDateChange(
            startDate.toISOString().split("T")[0] || new Date(Date.now()),
            selectedEndDate.toISOString().split("T")[0]
        );
    };

    return (
        <section className="flex items-center gap-4">
            <div className="flex flex-col border p-1 rounded">
                <small className="text-xs text-gray-500">Start Date</small>
                <input
                    type="date"
                    className="bg-gray-100 rounded p-1"
                    value={startDate.toISOString().split("T")[0]}
                    onChange={handleStartDateChange}
                />
            </div>
            <div className="flex flex-col border p-1 rounded">
                <small className="text-xs text-gray-500">End Date</small>
                <input
                    type="date"
                    className="bg-gray-100 rounded p-1"
                    value={endDate.toISOString().split("T")[0]}
                    onChange={handleEndDateChange}
                />
            </div>
        </section>
    );
};

const TableSubHeader = ({
    handleFilter,
    handleRefresh,
    handleDateChange,
    queryObject,
    filterTransaction,
}: Props) => {
    const id = useId();

    const removeFilter = (value: any) => {
        const result = Object.entries(queryObject).filter(e => e[1] !== value);
        filterTransaction(Object.fromEntries(result));
    };

    return (
        <div className="flex items-center justify-between w-full flex-nowrap">
            <div>
                <TagGroup>
                    {Object.values(queryObject).map((value: any) => (
                        <Tag
                            closable
                            size="md"
                            onClose={() => removeFilter(value)}
                            color="blue"
                            className="px-4 text-center"
                            key={`${id}_${value}`}
                        >
                            {value}
                        </Tag>
                    ))}
                </TagGroup>
            </div>
            <div className="flex flex-row flex-wrap gap-4">
                <DateTimeFilter handleDateChange={handleDateChange} />
                <FilterBtn handleFilter={handleFilter} />
                <RefreshBtn handleRefresh={handleRefresh} />
            </div>
        </div>
    );
};

export default TableSubHeader;
