import React, { useEffect, useState } from "react";
import DrawerPanel from "../../../components/elements/DrawerPanel/DrawerPanel";
import { ErrorToast } from "../../../components/blocks/Alert";
import Loading from "../../../components/elements/Loading/Loading";
import { GetTerminalDetailsApi } from "../../../redux/slice/posterminals/posterminals.api";
import { DbTerminalDetails } from "../../../types/api.types";
import formatWord from "../../../utils/formatWord";
import formatDate from "../../../utils/formatDate";

const assertValue = (value: unknown) =>
    ["string", "number"].includes(typeof value);

const PanelRow = ({ label, value }: { label: string; value: string }) => (
    <div className="text-sm space-y-1 capitalize border-b border-slate-300 border-dashed">
        <p className="text-sm text-slate-500 font-avenirBook">{label}</p>
        <p className="font-semibold text-indigo-800 etxt-base ">{value}</p>
    </div>
);

type Props = {
    terminalID: string;
    open: boolean;
    toggleModal(): void;
};

const TerminalDetails = ({ open, toggleModal, terminalID }: Props) => {
    const [terminalDetails, setTerminalDetails] = useState<DbTerminalDetails>();
    const [loading, setLoading] = useState(true);

    const getTerminalDetails = async () => {
        setLoading(true);
        try {
            const res = await GetTerminalDetailsApi(terminalID);
            setTerminalDetails(res.data);
        } catch (error) {
            ErrorToast(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getTerminalDetails();
    }, []);

    if (!terminalDetails)
        return (
            <DrawerPanel
                title="Terminal Details"
                description="Overview of this POS Terminal information"
                open={open}
                handleClose={toggleModal}
            >
                {loading ? (
                    <div className="w-fit my-10 mx-auto">
                        <Loading />
                    </div>
                ) : (
                    <div>No record found for this terminal</div>
                )}
            </DrawerPanel>
        );

    const {
        terminal: { createdAt, ...terminals },
        currentHolder,
        previousHolder,
    } = terminalDetails;

    return (
        <DrawerPanel
            title="Terminal Details"
            description="Overview of this POS Terminal information"
            open={open}
            handleClose={toggleModal}
        >
            <div className="space-y-3.5">
                {/* <PanelRow label="S/N" value={String(id)} /> */}
                {Object.entries(terminals).map(([key, value]) => {
                    if (!assertValue(value)) return null;
                    return (
                        <PanelRow
                            label={formatWord(key)}
                            value={String(value)}
                        />
                    );
                })}
                <PanelRow
                    label="Created At"
                    value={formatDate(new Date(createdAt))}
                />
                {currentHolder ? (
                    Object.entries(currentHolder).map(([key, value]) => {
                        if (!assertValue(value)) return null;
                        return (
                            <PanelRow
                                label={`Holder's ${formatWord(key)}`}
                                value={value}
                            />
                        );
                    })
                ) : (
                    <PanelRow label="Owner's Details" value="N/A" />
                )}
                {previousHolder ? (
                    Object.entries(previousHolder).map(([key, value]) => {
                        if (!assertValue(value)) return null;
                        return (
                            <PanelRow
                                label={`Previous's ${formatWord(key)}`}
                                value={value}
                            />
                        );
                    })
                ) : (
                    <PanelRow label="Previous Details" value="N/A" />
                )}
            </div>
        </DrawerPanel>
    );
};

export default TerminalDetails;
