import { validateAll } from "indicative/validator";
import React, { useState, useEffect } from "react";
import { Button, Modal } from "rsuite";
import { DrawerButton } from "../../../components/elements/Button";
import FloatingLabelInput from "../../../components/elements/Inputs/FloatingLabelInput";
import Loading from "../../../components/elements/Loading/Loading";
import { createPosAgentApi } from "../../../redux/slice/posagents/posagents.api";
import { getCommunitiesApi } from "../../../redux/slice/communities/communities.api";
import FloatingLabelSelectInput from "../../../components/elements/Inputs/FloatingLabelSelectInput/index";

type Props = {
    open: boolean;
    handleClose: () => void;
};

const AddAgent = ({ open, handleClose }: Props) => {
    const [formData, setFormData] = useState({
        fullName: "",
        phoneNumber: "",
        communityId: "",
    });
    const [error, setError] = useState<any>("");
    const [loading, setLoading] = useState(false);
    const [communities, setCommunities] = useState([]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const processCreate = async (data: any) => {
        setLoading(true);
        try {
            const res = await createPosAgentApi(data);
            if (res.successful) {
                handleClose();
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        const rules = {
            fullName: "required",
            phoneNumber: "required",
            communityId: "required",
        };
        const messages = {
            "fullName.required": "Fullname is required",
            "phoneNumber.required": "Phone Number is required",
            "communityId.required": "Community is required",
        };

        validateAll(formData, rules, messages)
            .then(() => processCreate(formData))
            .catch((errors: any) => {
                const formattedErrors = {} as any;
                errors.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setError(formattedErrors);
            });
    };

    const fetchCommunities = async () => {
        setLoading(true);
        try {
            const res = await getCommunitiesApi();
            const communityList = res.map((e: any) => ({
                name: e.displayCommunityName,
                value: e["_id"],
            }));

            setCommunities(communityList);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCommunities();
    }, []);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size="xs"
            backdropClassName="flex justify-center items-center"
        >
            <Modal.Header>
                <Modal.Title className="font-avenirBook">
                    Create Agent
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="pb-1">
                        <FloatingLabelInput
                            type="text"
                            label="Full Name"
                            onChange={e => handleInputChange(e)}
                            name="fullName"
                            value={formData.fullName}
                            error={error.fullName}
                        />
                    </div>
                    <div className="pb-1">
                        <FloatingLabelInput
                            type="tel"
                            label="Phone Number"
                            onChange={e => handleInputChange(e)}
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            error={error.phoneNumber}
                        />
                    </div>
                    <div className="pb-1">
                        <FloatingLabelSelectInput
                            options={communities}
                            label="Communities"
                            onChange={e => handleInputChange(e)}
                            name="communityId"
                            value={formData.communityId}
                            error={error.communityId}
                        />
                    </div>
                    <div className="flex flex-col justify-center">
                        <DrawerButton onClick={handleSubmit}>
                            {loading ? <Loading size="sm" /> : "Create Agent"}
                        </DrawerButton>
                        <Button
                            onClick={handleClose}
                            className="text-red-600 bg-slate-100 hover:text-red-600"
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default AddAgent;
