import React, { FormEvent, useEffect, useState } from "react";
import { Button, Modal, Form, Table } from "rsuite";
import { Plus } from "lucide-react";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import {
    addUsersToBucketApi,
    getBucketDetailsApi,
} from "../../../redux/slice/monitor/monitor.api";
import { ErrorToast, SuccessToast } from "../../../components/blocks/Alert";

interface Account {
    id: string;
    name: string;
    phone: string;
    date_created: string;
    dailyTransactions: number[];
    total: number;
}

const ActivityPage = () => {
    const navigate = useNavigate();
    const { bucketId } = useParams();
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [bucketDetails, setBucketDetails] = useState<any | null>(null);
    const [activityDetails, setActivityDetails] = useState<any | null>(null);
    const [open, setOpen] = useState(false);
    const [newAccount, setNewAccount] = useState({
        phone: "",
    });

    const handleReturn = () => {
        navigate("/monitor");
    };

    const handleSubmit = async (
        checkStatus: boolean,
        e: FormEvent<HTMLFormElement>
    ) => {
        e.preventDefault();

        if (!bucketId || !newAccount.phone) return;

        try {
            const payload = {
                bucketId,
                phoneNumbers: [newAccount.phone],
            };
            await addUsersToBucketApi(bucketId, payload.phoneNumbers);
            SuccessToast("User added successfully:");
        } catch (error) {
            ErrorToast("Couldn't Complete... Something Went Wrong");
            console.error("Validation error:", error);
        }

        setOpen(false);
    };

    useEffect(() => {
        if (!bucketId) return;

        const fetchBucketDetails = async () => {
            try {
                const date = new Date();

                //
                const specificDate = new Date("2024-11-12");
                const twoWeeksFromSpecificDate = new Date(specificDate);

                // Add 14 days to the specific date
                twoWeeksFromSpecificDate.setDate(
                    twoWeeksFromSpecificDate.getDate() + 14
                );

                // Set both dates to midnight to ignore time
                twoWeeksFromSpecificDate.setHours(0, 0, 0, 0);
                date.setHours(0, 0, 0, 0);

                if (twoWeeksFromSpecificDate.getTime() === date.getTime()) {
                    return;
                }

                const { data } = await getBucketDetailsApi(bucketId);
                setBucketDetails(data.data.bucket);
                setActivityDetails(data.data.bucket.activity);

                // Transform API data into accounts format
                const transformedAccounts = data.data.data.map(
                    (entry: any) => ({
                        id: entry.accountReference,
                        name: entry.accountName || "N/A",
                        phone: entry.phoneNumber || "N/A",
                        date_created: new Date(
                            bucketDetails?.createdAt || Date.now()
                        ).toLocaleDateString(),
                        dailyTransactions: entry.dailyCounts.map(
                            (count: { count: number }) => count.count
                        ),
                        total: entry.totalCount,
                    })
                );
                setAccounts(transformedAccounts);
            } catch (error) {
                console.error("Error fetching buckets:", error);
            }
        };
        fetchBucketDetails();
    }, [bucketId]);

    return (
        <div className="container mx-auto">
            <div className="flex flex-col gap-4">
                <Button
                    onClick={handleReturn}
                    appearance="ghost"
                    className="rounded-full flex items-center w-fit hover:bg-gray-100"
                >
                    <BiChevronLeft />
                    Go Back
                </Button>

                {/* Display Bucket and Activity Details */}
                {bucketDetails && (
                    <div className="">
                        <p className="text-xl font-semibold mb-2">
                            {bucketDetails.bucketName}
                        </p>
                        <p className="text-gray-500 m-0">
                            <strong>Monitoring Period:</strong>{" "}
                            {bucketDetails.monitoringPeriod} days
                        </p>
                        <p className="text-gray-500 m-0">
                            <strong>Created At:</strong>{" "}
                            {new Date(bucketDetails.createdAt).toLocaleString()}
                        </p>
                    </div>
                )}
                {activityDetails && (
                    <div className="border bg-gray-50 p-4 mb-4 rounded-lg">
                        <h4 className="text-xl font-semibold mb-4">
                            Activity Details
                        </h4>
                        <p>
                            <strong>Activity Name:</strong>{" "}
                            {activityDetails.activityName}
                        </p>
                        <p>
                            <strong>Description:</strong>{" "}
                            {activityDetails.description}
                        </p>
                        <p>
                            <strong>Data Type:</strong>{" "}
                            {activityDetails.dataType}
                        </p>
                    </div>
                )}

                <div className="flex justify-end items-center">
                    <Button
                        onClick={() => setOpen(true)}
                        appearance="primary"
                        className="flex items-center bg-blue-400"
                    >
                        <Plus className="mr-2 h-4 w-4" /> Add Account
                    </Button>
                </div>

                {/* Table to display account details */}
                <div className="border rounded">
                    <Table autoHeight data={accounts}>
                        <Table.Column width={150} align="center">
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.Cell dataKey="name" />
                        </Table.Column>
                        <Table.Column width={150} align="center">
                            <Table.HeaderCell>Phone</Table.HeaderCell>
                            <Table.Cell dataKey="phone" />
                        </Table.Column>
                        <Table.Column width={150} align="center">
                            <Table.HeaderCell>Date Created</Table.HeaderCell>
                            <Table.Cell dataKey="date_created" />
                        </Table.Column>
                        {Array.from({ length: 7 }, (_, i) => (
                            <Table.Column key={i} width={100} align="center">
                                <Table.HeaderCell>Day {i + 1}</Table.HeaderCell>
                                <Table.Cell
                                    dataKey={`dailyTransactions[${i}]`}
                                />
                            </Table.Column>
                        ))}
                        <Table.Column width={100} align="center">
                            <Table.HeaderCell>Total</Table.HeaderCell>
                            <Table.Cell dataKey="total" />
                        </Table.Column>
                    </Table>
                </div>
            </div>

            <Modal open={open} onClose={() => setOpen(false)} size="sm">
                <Modal.Header>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.ControlLabel>Phone</Form.ControlLabel>
                            <Form.Control
                                name="phone"
                                value={newAccount.phone}
                                onChange={(value: any) =>
                                    setNewAccount({
                                        ...newAccount,
                                        phone: value,
                                    })
                                }
                                placeholder="Enter phone number"
                            />
                        </Form.Group>
                        <Modal.Footer>
                            <Button
                                appearance="default"
                                type="submit"
                                className="bg-blue-500 hover:bg-blue-400 text-white"
                            >
                                Add Account
                            </Button>
                            <Button
                                onClick={() => setOpen(false)}
                                appearance="default"
                                className="bg-red-500 hover:bg-red-400 text-white"
                            >
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ActivityPage;
