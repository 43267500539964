import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "rsuite/dist/rsuite.min.css";
import "./assets/css/index.css";
import "./assets/css/tailwind.css";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import KickOff from "./routes/index";
import ErrorBoundary from "./components/hoc/ErrorBoundary";
import store from "./redux/store";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <StrictMode>
        <ErrorBoundary>
            <Provider store={store}>
                <KickOff />
            </Provider>
        </ErrorBoundary>
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
