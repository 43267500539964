import React, { ReactNode } from "react";
import { Modal } from "rsuite";

type Props = {
    open: boolean;
    toggleModal: () => void;
    // title?: string;
    children: ReactNode;
    // size?: "full" | "lg" | "md" | "sm" | "xs";
};
const ModalBox = ({
    open,
    toggleModal,
    title = "",
    size = "md",
    children,
    ...rest
}: Props & React.ComponentProps<typeof Modal>) => (
    <Modal
        open={open}
        onClose={toggleModal}
        size={size}
        backdropClassName="flex justify-center items-center"
        {...rest}
    >
        <Modal.Header>
            <Modal.Title className="font-gilroyBold capitalize">
                {title}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">{children}</Modal.Body>
    </Modal>
);
export default ModalBox;
