import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import terminalAPI from "./slice/terminals";

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(terminalAPI.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
