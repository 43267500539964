import { validateAll } from "indicative/validator";
import React, { useId, useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Button } from "rsuite";
import { SuccessToast } from "../../../components/blocks/Alert";
import { DrawerButton } from "../../../components/elements/Button";
import DrawerPanel from "../../../components/elements/DrawerPanel/DrawerPanel";
import FloatingLabelInput from "../../../components/elements/Inputs/FloatingLabelInput";
import Loading from "../../../components/elements/Loading/Loading";
import {
    createPosAgentApi,
    deletePosAgentApi,
} from "../../../redux/slice/posagents/posagents.api";
import formatWord from "../../../utils/formatWord";

type Props = {
    open: boolean;
    toggleModal: () => void;
    agent: any;
};

const Row = ({ title, value }: { title: string; value: any }) => (
    <div className="flex items-center justify-between py-4 text-xs border-b border-black border-dotted sm:text-sm">
        <div className="text-left">{title}:</div>
        <div className="font-bold text-right">{value}</div>
    </div>
);

const AgentDetail = ({ open, toggleModal, agent }: Props) => {
    const id = useId();
    const [canEdit, setCanEdit] = useState(false);
    const [formData, setFormData] = useState({
        fullName: agent.fullName,
        phoneNumber: agent.phoneNumber,
        posAgentId: agent.id,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>("");

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDelete = async () => {
        setLoading(true);
        const payload = {
            phoneNumber: formData.phoneNumber,
        };

        try {
            const res = await deletePosAgentApi(payload);
            if (res.successful) {
                SuccessToast(res.message);
                toggleModal();
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const processUpdate = async (data: any) => {
        setLoading(true);
        const { fullName, phoneNumber, posAgentId } = formData;
        const payload = {
            fullName,
            phoneNumber,
            posAgentId,
        };
        try {
            const res = await createPosAgentApi(payload);
            if (res.successful)
                SuccessToast("Agent details updated successfully");
            toggleModal();
        } catch (err) {
            setLoading(false);
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        const rules = {
            fullName: "required",
            phoneNumber: "required",
        };
        const messages = {
            "fullName.required": "Fullname is required",
            "phoneNumber.required": "Phone Number is required",
        };

        validateAll(formData, rules, messages)
            .then(() => processUpdate(formData))
            .catch((errors: any) => {
                const formattedErrors = {} as any;
                errors.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setError(formattedErrors);
            });
    };

    return (
        <DrawerPanel open={open} handleClose={toggleModal} title="Agent Detail">
            <div className="px-2 font-avenirBook">
                <div className="py-4 mb-2 text-xs bg-white">
                    {canEdit ? (
                        <form>
                            <div className="pb-1">
                                <FloatingLabelInput
                                    type="text"
                                    label="Fullname"
                                    onChange={e => handleInputChange(e)}
                                    name="fullName"
                                    value={formData.fullName}
                                    error={error.fullName}
                                />
                            </div>
                            <div className="pb-1">
                                <FloatingLabelInput
                                    type="tel"
                                    label="Phone Number"
                                    onChange={e => handleInputChange(e)}
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    error={error.phoneNumber}
                                />
                            </div>
                            <div className="flex flex-col justify-center">
                                <DrawerButton onClick={handleSubmit}>
                                    {loading ? (
                                        <Loading size="sm" />
                                    ) : (
                                        "Update Agent"
                                    )}
                                </DrawerButton>
                                <Button
                                    onClick={() => setCanEdit(!canEdit)}
                                    className="text-red-600 bg-slate-100 hover:text-red-600"
                                >
                                    Cancel
                                </Button>
                            </div>
                        </form>
                    ) : (
                        <>
                            {Object.entries(agent)
                                .filter(([key, value]) => key !== "id")
                                .map(e => (
                                    <Row
                                        title={formatWord(e[0])}
                                        value={e[1]}
                                        key={id + e[0]}
                                    />
                                ))}
                            <div className="flex flex-row justify-center mt-10 space-x-8">
                                <button
                                    type="button"
                                    className="px-4 py-2 text-sm text-white bg-blue-400 hover:bg-blue-600"
                                    onClick={() => setCanEdit(!canEdit)}
                                >
                                    <div className="flex flex-row justify-center gap-x-2">
                                        <FaEdit /> Edit
                                    </div>
                                </button>
                                <button
                                    type="button"
                                    className="px-4 py-2 text-xs font-bold text-white bg-red-500 hover:bg-red-600"
                                    onClick={handleDelete}
                                >
                                    {loading ? (
                                        <Loading size="sm" />
                                    ) : (
                                        <div className="flex flex-row justify-center gap-x-2">
                                            <FaTrash /> Delete
                                        </div>
                                    )}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </DrawerPanel>
    );
};
export default AgentDetail;
