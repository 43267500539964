import axios from "../../../axios";
import { AssignStaffToTeamType, TeamsType } from "../../../types/api.types";

export const getTeamsApi = async () => {
    try {
        const url = "/teams";
        const res = await axios.get(url);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const createTeamsApi = async (payload: TeamsType) => {
    try {
        const url = "/teams";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const assignStaffToTeamApi = async (payload: AssignStaffToTeamType) => {
    try {
        const url = "/lolastaffs/teams/assign";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};

export const deAssignStaffToTeamApi = async (
    payload: AssignStaffToTeamType
) => {
    try {
        const url = "/lolastaffs/teams/deassign";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (error) {
        throw error;
    }
};
