import axios from "../../../axios";

export const communitySuccessApi = async (
    staffUserId: string,
    specificCustomerPhone?: any
) => {
    try {
        const url = specificCustomerPhone
            ? `communitysuccess/feed?staffUserId=${staffUserId}&specificCustomerPhone=${specificCustomerPhone}`
            : `communitysuccess/feed?staffUserId=${staffUserId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const communitySuccessConversationsApi = async (payload: any) => {
    try {
        const url = `communitysuccess/conversations`;
        const response = await axios.post(url, payload);
        return response.data;
    } catch (err) {
        throw err;
    }
};
