import React, { useEffect, useState } from "react";
import { Button, Modal } from "rsuite";
import { validateAll } from "indicative/validator";
import { DrawerButton } from "../../../components/elements/Button";
import FloatingLabelInput from "../../../components/elements/Inputs/FloatingLabelInput";
import FloatingLabelSelectInput from "../../../components/elements/Inputs/FloatingLabelSelectInput";
import Loading from "../../../components/elements/Loading/Loading";
import { createStaffApi } from "../../../redux/slice/staff/staff.api";
import {
    CustomErrorToast,
    InfoToast,
    SuccessToast,
} from "../../../components/blocks/Alert";

type Props = {
    open: boolean;
    handleClose: () => void;
    staff: any;
};

const AddStaff = ({ open, handleClose, staff }: Props) => {
    const [formData, setFormData] = useState({
        lolaStaffUserPhoneNumber: "",
        operationsStaffLevel: "",
        worksInCs: "",
        lineManagerUserId: "",
    });
    const [lineManagers, setLineManagers] = useState([]);
    const [error, setError] = useState<any>("");
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const processCreate = async (data: any) => {
        setLoading(true);
        setLoading(false);
        try {
            const res = await createStaffApi(data);
            if (res.successful) SuccessToast("Staff created successfully");
            if (!res.successful) InfoToast(res.message);
            setLoading(false);
            handleClose();
        } catch (err: any) {
            setLoading(false);
            CustomErrorToast(err);
        }
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        const rules = {
            lolaStaffUserPhoneNumber: "required",
            operationsStaffLevel: "required",
            worksInCs: "required",
        };

        const messages = {
            "lolaStaffUserPhoneNumber.required":
                "Staff Phone Number is required",
            "operationsStaffLevel.required": "Staff level is required",
            "worksInCs.required": "Please select an option",
        };

        validateAll(formData, rules, messages)
            .then(() => processCreate(formData))
            .catch((errors: any) => {
                const formattedErrors = {} as any;
                errors.forEach((err: any) => {
                    formattedErrors[err.field] = err.message;
                });
                setError(formattedErrors);
            });
    };

    const options = [
        { name: "Customer Support", value: "CS" },
        { name: "Frontline Sales Officer", value: "FSO" },
        { name: "State Manager", value: "STATE_MANAGER" },
        { name: "HQ Employee", value: "HQ_EMPLOYEE" },
        { name: "HQ Manager", value: "HQ_MANAGER" },
    ];

    const CSOptions = [
        { name: "Yes", value: "true" },
        { name: "No", value: "false" },
    ];

    const filterForLineManager = () => {
        const res = staff
            .filter((e: any) => e.operationsStaffLevel === "STATE_MANAGER")
            .map((e: any) => ({
                name: `${e.userRecord.first_name} ${e.userRecord.last_name}`,
                value: e.lineManagerUserId,
            }));
        setLineManagers(res);
    };

    useEffect(() => {
        filterForLineManager();
    }, []);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            size="xs"
            backdropClassName="flex justify-center items-center"
        >
            <Modal.Header>
                <Modal.Title className="font-avenirBook">
                    Create Staff
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="pb-1">
                    <FloatingLabelInput
                        type="tel"
                        label="Staff Phone Number"
                        onChange={e => handleInputChange(e)}
                        name="lolaStaffUserPhoneNumber"
                        value={formData.lolaStaffUserPhoneNumber}
                        error={error.lolaStaffUserPhoneNumber}
                    />
                </div>
                <div className="pb-1">
                    <FloatingLabelSelectInput
                        options={options}
                        label="Operation Staff Level"
                        onChange={e => handleInputChange(e)}
                        name="operationsStaffLevel"
                        value={formData.operationsStaffLevel}
                        error={error.operationsStaffLevel}
                    />
                </div>
                <div className="pb-1">
                    <FloatingLabelSelectInput
                        options={lineManagers}
                        label="Line Manager"
                        onChange={e => handleInputChange(e)}
                        name="lineManagerUserId"
                        value={formData.lineManagerUserId}
                    />
                </div>
                <div className="pb-1">
                    <FloatingLabelSelectInput
                        options={CSOptions}
                        label="Work's in Customer Support"
                        onChange={e => handleInputChange(e)}
                        name="worksInCs"
                        value={formData.worksInCs}
                        error={error.worksInCs}
                    />
                </div>
                <div className="flex flex-col justify-center">
                    <DrawerButton onClick={handleSubmit}>
                        {loading ? <Loading size="sm" /> : "Create Staff"}
                    </DrawerButton>
                    <Button
                        onClick={handleClose}
                        className="text-red-600 bg-slate-100 hover:text-red-600"
                    >
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddStaff;
