import axios from "../../../axios";

export const createPosAgentApi = async (payload: any) => {
    try {
        const url = "/posagents";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const getAllPosAgentsApi = async (query?: any) => {
    try {
        const url = query ? `/posagents?phoneNumber=${query}` : "/posagents";
        const res = await axios.get(url);
        return res.data;
    } catch (err) {
        throw err;
    }
};

export const deletePosAgentApi = async (payload: any) => {
    try {
        const url = "/posagents/delete";
        const res = await axios.post(url, payload);
        return res.data;
    } catch (err) {
        throw err;
    }
};
