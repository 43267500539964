const formatWord = (camelCase: string) =>
    camelCase
        .replace(/\s/g, "")
        .replace(/([A-Z])/g, match => ` ${match}`)
        .replace(/([_])/g, match => ` `)
        .trim()
        .split(" ")
        .map(word => word[0].toUpperCase() + word.slice(1))
        .join(" ");

export default formatWord;
