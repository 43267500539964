/* eslint-disable no-underscore-dangle */
import { useNavigate } from "react-router";
import { Plus } from "@rsuite/icons";
import React, { useEffect, useState } from "react";
import AddBucket from "./AddBucket";
import { getBucketsApi } from "../../../redux/slice/monitor/monitor.api";

const MonitoringPage = () => {
    const navigate = useNavigate();
    const [BucketsCount, setBucketsCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [buckets, setBuckets] = useState([]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleActivity = (bucketId: string) => {
        navigate(`/monitor/activities/${bucketId}`);
    };

    useEffect(() => {
        const fetchBuckets = async () => {
            try {
                const { data } = await getBucketsApi();
                // console.log("Buckets: ", data);
                if (data?.data) {
                    setBuckets(data.data);
                    setBucketsCount(data.data.length);
                }
            } catch (error) {
                console.error("Error fetching buckets:", error);
            }
        };
        fetchBuckets();
    }, []);

    return (
        <>
            <div className="mx-auto">
                <div className="p-4">
                    <h1 className="text-4xl font-bold mb-8">Monitoring Page</h1>
                    <div className="flex justify-between items-start md:gap-8 mb-4">
                        <div className="bg-gray-100 grow p-4 rounded-lg text-center max-w-sm">
                            <p className="text-sm text-gray-500">
                                No. of Buckets
                            </p>
                            <div className="text-3xl font-bold">
                                {BucketsCount}
                            </div>
                        </div>
                        <button
                            type="button"
                            onClick={() => setOpen(true)}
                            className="flex items-center bg-blue-500 text-white px-4 py-2 rounded shadow"
                        >
                            <Plus className="mr-2 h-4 w-4" />
                            Add Bucket
                        </button>
                    </div>

                    {/* Buckets Cards */}
                    <div className="flex flex-col my-8">
                        <h3 className="text-2xl font-bold mb-3">Buckets</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {buckets.length > 0 ? (
                                buckets.map((bucket: any) => (
                                    <div
                                        key={bucket._id}
                                        role="button"
                                        onClick={() =>
                                            handleActivity(bucket._id)
                                        }
                                        className="p-4 border border-gray-300 rounded-lg transition-shadow hover:shadow-lg bg-white cursor-pointer"
                                    >
                                        <h4 className="text-lg font-semibold ">
                                            {bucket.bucketName}
                                        </h4>
                                        <p className="text-xs text-gray-400 mt-2">
                                            Created on:{" "}
                                            {new Date(
                                                bucket.createdAt
                                            ).toLocaleDateString()}
                                        </p>
                                    </div>
                                ))
                            ) : (
                                <p className="text-gray-500">
                                    No buckets available.
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <AddBucket open={open} handleClose={handleClose} />
        </>
    );
};

export default MonitoringPage;
