import { createApi } from "@reduxjs/toolkit/query/react";
import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import type { AxiosRequestConfig, AxiosError } from "axios";
import axios from "../../../axios";
import debug from "../../../utils/debug";
import type {
    APIResponse,
    DbAllTerminals,
    GetPosTerminalApiQueryParams,
} from "../../../types/api.types";

type GetAllTerminals = APIResponse<DbAllTerminals[]>;
type GetAllTerminalsRequest = APIResponse<{ requests: DbAllTerminals[] }>;

type BaseQueryParams = {
    url: string;
    method: AxiosRequestConfig["method"];
    data?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
};

type TArgs = GetPosTerminalApiQueryParams | void;

type AxiosBaseQuery = BaseQueryFn<BaseQueryParams>;

const axiosBaseQuery =
    (): AxiosBaseQuery =>
    async ({ url, method, data, params }) => {
        try {
            const result = await axios({
                url,
                method,
                data,
                params,
            });
            return { data: result.data };
        } catch (axiosError) {
            const err = axiosError as AxiosError;

            debug.log(err);

            throw err;
        }
    };

const terminalAPI = createApi({
    baseQuery: axiosBaseQuery(),
    tagTypes: ["Pos Terminals"],
    endpoints: builder => ({
        getAllRequestedTerminals: builder.query<GetAllTerminalsRequest, TArgs>({
            query: queryParams => ({
                url: "/terminal/request/all",
                method: "get",
                params: queryParams,
            }),
            providesTags: [
                { type: "Pos Terminals", id: "Requested Terminals" },
            ],
        }),
        getAllTerminals: builder.query<GetAllTerminals, TArgs>({
            query: queryParams => ({
                url: "/terminal",
                method: "get",
                params: queryParams,
            }),
            providesTags: [{ type: "Pos Terminals", id: "All Terminals" }],
        }),
    }),
});

export const {
    useGetAllRequestedTerminalsQuery,
    useGetAllTerminalsQuery,
    endpoints,
} = terminalAPI;

export default terminalAPI;
